import React from "react"
import {Container, Row, Col} from "reactstrap"
import {graphql} from "gatsby"
import * as R from "ramda"
import styled from "styled-components"
import Layout from "../components/layout2"
import SEO from "../components/seo"
import SinglePost from "../components/SinglePost"
import SeriesSidebar from "../components/SeriesSidebar"

const StyledWrapper = styled.div`
  &#single-series-page {
    .single-series-page__post {
    }
  }
`

const SingleSeriesSinglePostPage = props => {
  const {series} = props.pageContext
  const {allPosts, post, site} = props.data
  const currentPost = post.edges[0]
  return (
    <Layout>
      <SEO
        title={currentPost.node.frontmatter.title}
        pathname={props.location.pathname}
        imagePath={
          currentPost.node.frontmatter?.image?.childImageSharp?.fluid?.src
        }
        type="article"
        description={currentPost.node.excerpt}
        keywords={currentPost.node.frontmatter?.tags}
        isBlogPost
      />
      <StyledWrapper id="single-series-page">
        <Container fluid>
          <Row>
            <Col md="3">
              <SeriesSidebar
                seriesTitle={series}
                edges={allPosts.edges}
                currentPostTitle={R.pathOr(
                  null,
                  ["node", "frontmatter", "title"],
                  currentPost
                )}
              />
            </Col>
            <Col md="9">
              <div className="global_base-post-section">
                <SinglePost
                  key={currentPost.node.id}
                  title={currentPost.node.frontmatter.title}
                  date={currentPost.node.frontmatter.date}
                  author={currentPost.node.frontmatter.author}
                  slug={currentPost.node.fields.slug}
                  fluid={
                    currentPost.node.frontmatter?.image?.childImageSharp?.fluid
                  }
                  tags={currentPost.node.frontmatter.tags}
                  html={currentPost.node.html}
                  shareLink={`${site.siteMetadata.url}${props.location.pathname}`}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </StyledWrapper>
    </Layout>
  )
}

export const singleSeriesQuery = graphql`
  query($series: String!, $title: String!) {
    allPosts: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: ASC}
      filter: {
        frontmatter: {series: {in: [$series]}}
        fields: {draft: {eq: false}}
      }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD ddd")
            author
            tags
            series
          }
          fields {
            slug
          }
        }
      }
    }
    post: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: ASC}
      filter: {
        frontmatter: {series: {in: [$series]}, title: {in: [$title]}}
        fields: {draft: {eq: false}}
      }
    ) {
      totalCount
      edges {
        node {
          id
          html
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD ddd")
            author
            tags
            series
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    site: site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
  }
`

export default SingleSeriesSinglePostPage
