import React from "react"
import { Feather } from "react-feather"
import { Link } from "gatsby"

import styled from "styled-components"
import { lighten } from "polished"
import { slugify_chinese } from "../util/utilityFunctions"

const StyledLink = styled(Link)`
  color: ${props =>
    props.highlight === "isActive"
      ? props.theme.sidebarActiveFontColor
      : props.theme.tagColor};
  &:hover {
    color: ${props => {
      if (props.highlight === "isActive") {
        return lighten(0.2, props.theme.sidebarActiveFontColor)
      }
      return lighten(0.2, props.theme.tagColor)
    }};
    text-decoration: none;
  }
`

const StyledWrapper = styled.div`
  &#seriesSidebar {
    border-right: 1px solid #ccc;
    height: 100%;
    .seriesSidebar__nav {
      list-style: none;
      padding: 0;
    }

    .seriesSidebar__nav-item {
      list-style-type: none;
      line-height: 2;
      margin: 6px 0;
    }
    .seriesSidebar__nav-item__text {
      margin-left: 5px;
    }
  }
`

const SeriesSidebar = ({ seriesTitle, edges, currentPostTitle }) => {
  return (
    <StyledWrapper id="seriesSidebar">
      <h3>{seriesTitle}</h3>
      <div>
        <ul className="seriesSidebar__nav">
          {edges.map(item => {
            const isActive = currentPostTitle === item.node.frontmatter.title
            return (
              <li
                key={item.node.id}
                className={
                  isActive
                    ? "seriesSidebar__nav-item is-active"
                    : "seriesSidebar__nav-item"
                }
              >
                <StyledLink
                  highlight={isActive ? "isActive" : undefined}
                  to={`/series/${slugify_chinese(seriesTitle)}/${
                    item.node.fields.slug
                  }`}
                >
                  <Feather size="24" />
                  <span className="seriesSidebar__nav-item__text">
                    {item.node.frontmatter.title}
                  </span>
                </StyledLink>
              </li>
            )
          })}
        </ul>
      </div>
    </StyledWrapper>
  )
}

export default SeriesSidebar
